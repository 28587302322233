import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout';
Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'login',
	component: (resolve) => require(['@/views/login'], resolve),
}, {
	path: '/user',
	component: Layout,
	hidden: true,
	children: [{
		path: 'userList',
		name: 'userList',
		component: (resolve) => require(['@/views/userList/index'], resolve),
	}, {
		path: 'recoverList',
		name: 'recoverList',
		component: (resolve) => require(['@/views/recover/index'], resolve),
	}]
}, {
	path: '/new',
	component: Layout,
	hidden: true,
	children: [{
		path: 'newList',
		name: 'newList',
		component: (resolve) => require(['@/views/newList/index'], resolve),
	}]
}]

const router = new VueRouter({
	routes
})

export default router