<template>
	<div class="menu-bar " :class="collapse?'menu-bar-width':'menu-bar-collapse-width'">
		<div class="logo">
			<div class="d_f a_i_c" v-if="collapse==true">
				<img src="@/assets/indexIcon.png" />
				<span>智慧劝导系统</span>
			</div>
			<img src="@/assets/indexIcon.png" v-else />
		</div>

		<div class="nav">

			<el-scrollbar wrap-style="overflow-x:hidden;" style="height: 100%;z-index: 99999;">
				<el-menu :default-active="activeMenu" :tabindex="activeMenu" :unique-opened="true" background-color="#000c2f" text-color="#d9dee5"
					active-text-color="#00aaff" :collapse="!collapse" class="el-menu-vertical-demo">
					<div v-for="(item,index) in user_routes" :key="index">
						<el-submenu :index="item.path" v-if="item.children.length>0">
							<template slot="title" v-if="item.meta">
								<i :class="item.meta.icon"></i>
								<span>{{item.meta ? item.meta.title : ''}}</span>
							</template>
							<component :is="type" v-bind="linkProps(child.path)" v-for="(child,ind) in item.children" :key="ind">
								<el-menu-item :index="child.path">{{child.title}}</el-menu-item>
							</component>
						</el-submenu>
						<template v-if="item.children.length==0">
							<component :is="type" v-bind="linkProps(item.path)">
								<el-menu-item :index="item.path">{{item.meta ? item.meta.title : ''}}</el-menu-item>
							</component>
						</template>
					</div>
				</el-menu>
			</el-scrollbar>
		</div>

	</div>
</template>

<script>
	import {
		isExternal
	} from '@/utils/common' 
	import {
		mapState,
		mapGetters
	} from 'vuex'
	export default { 
		data() {
			return {
				user_routes: [{ 
					name: "User",
					path: "/user",
					hidden: false,
					meta: {
						icon: "el-icon-s-tools",
						title: "系统管理"
					},
					children: [{
						name: "userList",
						path: "/user/userList",
						hidden: false,
						title: "用户管理", 
					},{
						name: "recoverList",
						path: "/user/recoverList",
						hidden: false,
						title: "劝导记录", 
					}]
				},{
					name: "New",
					path: "/new",
					hidden: false,
					meta: {
						icon: "el-icon-s-management",
						title: "文章管理"
					},
					children: [{
						name: "newList",
						path: "/new/newList",
						hidden: false,
						title: "文章管理", 
					} ]
				}]
			}
		},
		computed: {
			...mapState({
				collapse: state => state.app.collapse,
			}),
			activeMenu() {
				const route = this.$route;
				const {
					meta,
					path
				} = route;
				// if set path, the sidebar will highlight the path you set 
				if (meta.activeMenu) {
					return meta.activeMenu;
				}
				return path;
			},
			isExternal() {
				return isExternal(this.to)
			},
			type() {
				if (this.isExternal) {
					return 'a'
				}
				return 'router-link'
			}
		},
		methods: {
			linkProps(to) { 
				if (this.isExternal) {
					return {
						href: to,
						target: '_blank',
						rel: 'noopener'
					}
				}
				return {
					to: to,
					target: ''
				}
			}
		}
	}
</script>

<style lang="scss">
	.menu-bar {
		position: fixed;
		top: 0px;
		left: 0;
		bottom: 0;
		z-index: 1020;
		background: #000c2f;
		text-align: left;
		font-size: 12px !important;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);

		.logo {
			// position: absolute;
			// top: 0px;
			height: 70px;
			line-height: 70px;
			width: 100%;
			cursor: pointer;
			text-align: center;
			margin-top: 10px;

			img {
				width: 40px;
				height: 40rpx;
				border-radius: 50%;
				margin-right: 10px;
			}

			div {
				font-size: 16px;
				color: white;
				text-align: left;
				margin-left: 20px;
			}
		}

		.usertop {
			padding-top: 60px;
			padding-left: 20px;
			padding-bottom: 10px;
			padding-right: 20px;
			text-align: center;
			min-height: 60px;
			display: flex;
			align-items: center;

			.thumb-md {
				height: 48px;
				width: 48px;
				border-radius: 50%;
				float: left;
			}

			.user-info {
				float: left;
				color: #98a6ad;
				margin-left: 10px;

				.el-dropdown-link {
					color: #FFFFFF;

				}

			}
		}

		.el-menu-item [class^=fa],
		.el-submenu__title [class^=fa] {
			margin-right: 5px;
			width: 24px;
			font-size: 12px;
			text-align: center;
		}
	}

	.menu-bar-width {
		width: 190px;
	}

	.menu-bar-collapse-width {
		width: 65px;
	}

	.usertops {
		padding-top: 60px;
		padding-left: 5px !important;
		padding-bottom: 10px !important;
		padding-right: 0px !important;
	}

	.nav {
		height: calc(100% - 130px);

		.el-menu-vertical-demo:not(.el-menu--collapse) {
			width: 190px;
			// min-height: 400px;
			height: 100%;
		}

		.el-menu {
			background: transparent;
			border-right: 0 !important;

			.el-menu-item,
			.el-submenu__title {
				height: 50px !important;
				line-height: 50px !important;
				font-size: 13px !important;
				width: 190px;

				i {
					color: #d9dee5 !important;
				}

			}

			.el-submenu>>>.el-submenu__title {
				position: relative;
				z-index: 999;
			}


		}

	}

	.menu-bar-collapse-width .nav .el-submenu .el-submenu__title {
		width: 64px !important;
		display: inline-block;
		overflow: hidden;

		span {
			height: 0;
			width: 0;
			overflow: hidden;
			visibility: hidden;
			display: inline-block;
		}

		.el-submenu__icon-arrow {
			display: none;
		}



	}

	.el-menu-item {
		font-size: 12px !important;
		height: 50px !important;
		line-height: 50px !important;
	}

	.el-menu a {
		display: inline-block;
		width: 100%;
		overflow: hidden;
		text-decoration: none !important;
	}

	.el-tooltip__popper {
		left: 64px !important;
	}
</style>