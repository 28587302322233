import {
	MessageBox,
	Message
} from 'element-ui'
export default {
	uploadUrl: "https://img.yuexianghuan.com",
	imgUrl: "http://operate.yuexianghuan.com/uploads",
	baseUrl: "https://police.yuexianghuan.com/admin",

	// baseUrl: "http://192.168.16.138/enjoy/public",
	// imgUrl: "http://192.168.16.138/enjoy/public/uploads", 
}