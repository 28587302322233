import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import VueParticles from 'vue-particles' 
import 'element-ui/lib/theme-chalk/index.css';  
import  * as request from '@/utils/request'
import baseUrl from '@/utils/baseUrl'
// import adminauth from '@/utils/adminauth'
Vue.config.productionTip = false
Vue.prototype.$request = request
Vue.use(ElementUI);
Vue.use(VueParticles)
Vue.mixin({
	data() {
		return {
			 
		}
	},
	methods: {
		getUrl(src) {
			if (src) { 
				return src.indexOf('http') > -1 ?
					src : baseUrl.baseUrls + src
			} else {
				return require('@/assets/empty-face.png')
			}
		},
	}
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
