<template>
	<div class="top" :class="collapse ? 'position-left' : 'position-collapse-left'">
		<div class="top-bar">
			<i class="coll" :class="collapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'" @click="ClickCollapse"></i>
			<!-- <bread-name id="breadcrumb-container" class="mbiao"></bread-name> -->
			<!-- <breadcrumb id="breadcrumb-container" class="mbiao" /> -->
			<span class="toolbar" v-if="device=='desktop'">
				<el-menu class="el-menu-demo" text-color="#666">
					<!-- <el-menu-item index="0" @click="to_work">
						<el-badge :value="pData" :max="99" class="badge" :hidden="pData<=0">
							<li class="el-icon-message-solid" style="font-size: 20px;margin-top: 5px;"></li>
						</el-badge>
					</el-menu-item> -->
					<el-menu-item index="1">
						<!-- 用户信息 -->
						<span class="user-info">
							<el-dropdown>
								<span class="el-dropdown-link">
									<img class="avatar" :src="getUrl(form.avatar)" />
									<span>{{form.name ? form.name : '超级管理员'}}</span>
								</span>
								<el-dropdown-menu slot="dropdown">
									<!-- <el-dropdown-item @click.native="open = true"><i class="el-icon-user"></i>编辑资料</el-dropdown-item> -->
									<el-dropdown-item @click.native="open = true"><i class="el-icon-unlock"></i>修改密码</el-dropdown-item>
									<el-dropdown-item @click.native="loginout()"><i
											class="el-icon-switch-button"></i>退出登录</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</span>
					</el-menu-item>
					<!-- <el-menu-item><i class="fa fa-power-off" style="font-size: 14px;"></i></el-menu-item>
					<el-menu-item><i class="fa fa-clone"></i></el-menu-item> -->
				</el-menu>
			</span>
		</div>
		<el-dialog :visible.sync="open" width="400px" append-to-body title="修改密码">
			<el-form ref="form" :model="formData" :rules="rules" label-width="80px">
				<el-form-item label="原密码" prop="old_password">
					<el-input type="password" show-password v-model="formData.old_password" placeholder="请输入原密码" size="small" />
				</el-form-item>
				<el-form-item label="新密码" prop="password">
					<el-input type="password" show-password v-model="formData.password" placeholder="请输入新密码" size="small" />
				</el-form-item>
				<el-form-item label="确认密码" prop="con_password">
					<el-input type="password" show-password v-model="formData.con_password" placeholder="请输入确认密码" size="small" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="open = false" size="small">取 消</el-button>
				<el-button type="primary" @click="submitForm('form')" size="small">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapState,
		mapGetters
	} from 'vuex'
	import {
		removeToken
	} from '@/utils/token'
	
	export default {
		data() {
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.formData.password) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				open: false,
				form:{},
				formData: {
					"old_password": "",
					"password": "",
					'con_password': ''
				},
				rules: {
					old_password: [{
						required: true,
						message: '请输入旧密码',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入新密码',
						trigger: 'blur'
					}],
					con_password: [{
						required: true,
						validator: validatePass2,
						trigger: 'blur'
					}], 
				},
				pData: 0,
			}
		},
		computed: {
			...mapState({
				collapse: state => state.app.collapse,
				device: state => state.app.device
			})
		},
		created() {
			this.form = JSON.parse(localStorage.getItem('userInfo'))
		},
		methods: {
			submitForm: function(data) {
				let _self = this
				_self.$refs[data].validate((valid) => {
					if (valid) {
						_self.$request.post('/index.Index/updatePassword', _self.formData).then(res => {
							console.log(res)
							if (res.code == 200) {
								_self.$message.success('修改成功');
								_self.open = false;
								removeToken()
								location.href = '/#/';
							} else {
								_self.$message.error(res.msg);
							}
						})
					} else {
						return false;
					}
				})
			},
			ClickCollapse() {
				this.$store.dispatch('app/changeCollapse')
			},
			loginout: function() {
				console.log(this.name);
				this.$confirm('确定注销并退出系统吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// this.$refs.tabBar.tabsCloseAllHandle(this.$refs.tabBar.selectedTag)
					removeToken()
					location.href = '/#/';
				})
			},
			// 上传附件
			uploadSuccess: function(res) {
				let _self = this
				console.log();
				if (res.code == 200) {
					// _self.init();
					_self.form.face = res.data.urls
					_self.msgSuccess(res.msg);
				} else {
					_self.msgError(res.msg);
				}
			},
			uploadError: function(res) {
				let _self = this
				_self.msgError("后端错误，请联系管理员");
			},
		}
	}
</script>

<style lang="scss">
	.top {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 1030;
		height: 60px;
		// width:calc(100% - 190px);
		background: #fff;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

		.top-bar {
			height: 60px;
			line-height: 60px;
			border-bottom: #eee solid 1px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.coll {
				padding: 15px;
				color: #666;
				font-size: 25px;

				&:hover {
					background: #fff;
				}
			}

			.toolbar {
				height: 50px;
				padding-top: 10px;
				color: #666;

				.el-dropdown {
					color: #666;
				}

				.el-menu {
					background: #fff;
					border-right: none;
				}

				.el-menu-item:hover {
					background: #fff;
				}

				// padding-right: 50px;
				.el-menu-item {
					padding: 0 20px 0px 0px !important;
				}

				.el-menu-item,
				.el-submenu__title {
					height: 39px !important;
					line-height: 39px !important;
					float: left;
				}

				.el-badge__content.is-fixed {
					top: 15px;
				}

				.user-info img {
					width: 40px;
					height: 40px;
					margin-right: 15px;
					border-radius: 50%;
				}

				.el-dropdown-link {
					display: flex;
					align-items: center;
				}
			}
		}
	}

	.uploadcol {
		display: flex;
		flex-flow: column;
		width: 100px;
	}

	.uploadborder {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		width: 100px;
		height: 100px;
	}

	.mbiao {
		line-height: 45px !important;
		font-size: 12px !important;
		float: left;
	}
</style>